import React from 'react'
import { Link } from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    SaaS Solutions
                                </Link>
                            </h3>

                            <p>We built many SaaS applications from scratch. We cover all areas of SaaS system development and maintenance: Architecture, Development, Infrastructure, Billing, and Maintenance.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    eCommerce platforms integration
                                </Link>
                            </h3>

                            <p>Each SaaS project needs a good billing solution and payment systems integrations. A smooth payment experience can dramatically increase sales and decrease churn.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="#">
                                    High-load infrastructure
                                </Link>
                            </h3>
                            <p>
                                We keep scalability and stability in mind while preparing project architecture. Project infrastructure is built on top of modern cloud technologies. 
                            </p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    Legacy code refactoring
                                </Link>
                            </h3>

                            <p>If you have a burden of legacy code, we can help you to refactor it using modern programming paradigms. Legacy code can significantly slow down your revenue growth. It is better to address it promptly.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    Data collection and processing
                                </Link>
                            </h3>

                            <p>
                                Each SaaS project has to collect and process data. We build solutions in compliance with modern legal frameworks, including GDPR.
                            </p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                    Support & Maintenance
                                </Link>
                            </h3>

                            <p>Once a SaaS project is up and running it needs continuous maintenance and monitoring. We increase your revenue by adding more value for end-users.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne